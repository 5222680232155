<template>
    <div class="personal">
        <!-- 个人中心 -->
        <div class="regionTopImg" :style="{ backgroundImage: `url(${bannerUrl})` }">
            <!-- <img :src="bannerUrl" alt=""> -->
        </div>
        <div class="region">
            <div class="contentRegion">
                <div class="item" @click="choice('record')">
                    <div class="imgBox">
                        <img src="../../assets/home/yuyuezixun.png" alt="" />
                    </div>
                    
                    <span>预约咨询</span>
                </div>
                <div class="item" @click="choice('myRecord')">
                    <div class="imgBox">
                        <img src="../../assets/home/wodeyuyue.png" alt="" />
                        </div>
                        <span>我的预约</span>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        choice(val) {
            this.$router.push(`/home/${val}`)
        }
    },
    data() {
        return {
            bannerUrl: ''
        }
    },
    created() {
        this.bannerUrl = sessionStorage.getItem('banner')
    }
}
</script>

<style lang="scss" scoped>
.personal {

    // box-shadow: 0px 2px 4px 0px rgba(229,0,74,0.3);
    // height: 100%;
    .regionTopImg {
        img {
            height: 100%;
        }
    }

    .contentRegion {
        background: white;
        padding-top: 20px;

        // height: 100%;
        // height: 100%;

        .item {
            margin: auto;
            // box-shadow: 0px 2px 4px 0px rgba(148, 146, 146, 0.25);
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1700);
            height: 110px;
            width: 650px;
            margin-bottom: 20px;
            border-radius: 10px;
            display: flex;
            // justify-content: space-between;
            justify-content: center;
            padding: 0 10px;
            align-items: center;
            box-sizing: border-box;

            &:nth-child(1) {
                span {
                    color: #369119;
                }
            }

            &:nth-child(2) {
                span {
                    color: #C4A615;
                }
            }

            &:nth-child(3) {
                span {
                    color: #8FABB5;
                }
            }

            &:nth-child(4) {
                span {
                    color: #A9AE58;
                }
            }

            
            .imgBox{
                        // line-height: 40px;
                        height: 40px;
                        width: 35px;
                        // margin-right: 10px;
            
                    img {
                        line-height: 100%;
                        height: 70%;
                        // margin-right: 10px;

                        // width: 40px;
                    
                    }
                


            }
            span{
                display: inline-block;
                height: 40px;
            }


           
        }
    }
}

@media screen and (min-width: 751PX) {
    .personal {
        .top {
            height: 80px;
        }

        // background-image: url('../../assets/home/backgroundImage.png');
        // background-repeat: no-repeat;
        // background-position: center;
        // background-attachment: fixed;
        background-size: cover;
        // display: flex;
        // justify-content: center;
    }

    .item {
        span {
            font-size: 20px;
        }
    }

    .regionTopImg {
        height: 200px;

        width: 100%;
        // background-image: url('../../assets/home/banner.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
    }

}

@media screen and (max-width: 750PX) {
    .regionTopImg {
        height: 150px;
        width: 100%;
        // background-image: url('../../assets/home/banner.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .personal {
        width: 100%;



        .contentRegion {
            background: white;

            .item {
                width: 80%;

                span {
                    // font-weight: 400;
                    font-size: 16px;
                }
            }
        }
    }
}
</style>